import Image from 'next/image';
import React from 'react';
import getStrapiImage from '../utils/Z_Utils';


const Z_Strapi_Image = ({
                            image,
                            size = null,
                            quality = 100,
                            ...props
                        }) => {

    const strapiImage = getStrapiImage(
        image?.data?.attributes,
        size,
    );

    const fill = props.fill
        ?? false;
    const moreAttributes = fill
        ? {}
        : {
            width: strapiImage?.width,
            height: strapiImage?.height, // sizes:  '(max-width: 768px) 100vw,(max-width: 1200px) 50vw, 33vw',
        };

    const altProp = props.alt
        ?? strapiImage?.alternativeText;

    if (strapiImage) {
        return (
            <Image
                src={process.env.NEXT_PUBLIC_SITE_API_LINK
                    + strapiImage.url}

                alt={altProp}

                /*Default sizes*/
                sizes={'(max-width: 768px) 100vw,(max-width: 1200px) 50vw,33vw'}

                quality={quality}
                {...props}
                {...moreAttributes}
            />
        );
    }
};


export const Z_Strapi_SVG = ({
                                 svg,
                             }) => {

    const strapiSVG = svg?.data?.attributes
        ?? null;

    if (strapiSVG) {
        return (
            <></>
        );
    }
};

export default Z_Strapi_Image;
